import React from "react"
import FotoKontigo from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cepeban/FotoCepeban.png"
import FotoKontigo2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/cepeban/FotoCepeban2.png"

//logo de empresas clientes
import Cepeban from "@components/pageCasosdeExito/image/logoscustomer/Cepeban.svg"

//iconos de modulos
import whatsapp from "@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import chatbots from "@components/pageCasosdeExito/image/iconcards/chatbot.svg"
import integrar from "@components/pageCasosdeExito/image/iconcards/integrarApi.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/cepeban/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/cepeban/img2.svg"

const text1 = (
  <span className="">
    CEPEBAN, el principal instituto especializado en banca y finanzas en el Perú
    cuenta con 38 años formando profesionales en este sector, tomando como base
    la bancarización, innovación y las necesidades del campo económico
    financiero del mercado.
    <br /> <br />
    Con la ayuda de Beex, la entidad educativa es capaz de atender a más de
    1.5k leads al mes, logrando mejorar su conversión en +27%. Todo esto,
    gracias a los chatbots de prospección por WhatsApp y enrutamiento
    inteligente de casos.
  </span>
)

const text2 = (
  <span>
    CEPEBAN, cuenta con especializaciones en Administración Bancaria y Finanzas,
    Administración de Empresas y Contabilidad, y más. Esto y sus métodos
    educativos en el sector lo han llevado a ser considerado como el principal
    instituto de formación bancaria en el Perú
    <br /> <br />
    La institución educativa es la única en finanzas que ha recibido la
    renovación institucional por MINEDU (Ministerio de Educación del Perú),
    debido a sus métodos de estudios, infraestructura académica, tecnología e
    innovación. Con ello, continúa su compromiso de seguir llevando educación de
    calidad a más estudiantes.
    <br /> <br />
    Oriana Huamán, Jefa de Marketing de CEPEBAN, ha sido uno de los principales
    contactos para poner en marcha nuestra solución omnicanal.
    <br /> <br />
    Desde el inicio, el punto más importante que la institución educativa quería
    mejorar era la administración y contacto de sus leads que llegaban por canal
    digital. Esto, llevó a CEPEBAN a buscar una plataforma que pueda gestionar
    de manera escalable su canal más importante de contacto y conversión, que es
    WhatsApp.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    “Gracias al API de WhatsApp Business somos capaces de administrar la gestión
    de nuestros leads y clientes de forma más eficiente que antes. Esto, junto
    con las integraciones que hemos logrado implementar, ha hecho que mejoremos
    nuestra tasa de conversión en canal digital en 27%.”
  </p>
)

const text4 = (
  <span>
    Es por ello que se tomó la decisión de migrar a la versión de WhatsApp
    Business API, ya que les iba a brindar las funcionalidades que necesitaba la
    institución para mejorar el performance de su canal principal de contacto.
    <br />
    <br />
    Como nos dice Oriana, esta versión oficial de WhatsApp les ha brindado las
    mejoras que ellos buscaban en el canal, además de otras opciones, las cuales
    los han llevado a mejorar sus métricas más importantes de marketing y
    ventas, como su tasa de conversión en su canal digital.
  </span>
)

const text5 = (
  <span>
    Como mencionamos al inicio, la gestión óptima de leads de CEPEBAN fue el
    punto principal a optimizar en su operación de marketing. Con esto, se dio
    de alta su canal oficial de WhatsApp, con la versión Business API. Luego,
    teníamos que implementar los procesos ideales para este fin.
    <br /> <br />
    Gracias al acompañamiento de Beex Customer Success, la operación
    digital de la institución diseñó, creo e implementó un chatbot por WhatsApp
    que realice el primer contacto con los leads que llegan desde diferentes
    canales digitales, como su web y redes sociales.
    <br /> <br />
    Antes de Beex, la institución contaba con diferentes números de WhatsApp
    para grupos de asesores comerciales que puedan brindar información y cerrar
    acuerdos con potenciales estudiantes, pero con esta nueva estrategia, se
    optimizaron los tiempos de la operación en general.
    <br /> <br />
    Como nos cuenta Oriana, ahora, el chatbot en WhatsApp de CEPEBAN atiende a
    tus leads de digital, brindándoles la información que ellos buscan y, sobre
    todo, contando con un flujo automatizado que les permite conversar con sus
    leads y saber en qué nivel de prospección se encuentran. Con esto, la
    institución es capaz de brindar información más adecuada y precisas a sus
    contactos.
  </span>
)
const text6 = (
  <span>
    “Con Beex y los chatbots implementados en WhatsApp, gestionamos de manera
    más eficiente la atención a nuestros más de 1.5k de prospectos al mes que
    están interesados en pertenecer a nuestra institución. Ahora no tenemos
    ningún mensaje sin contestar, lo que antes se traducía en la pérdida de
    posibles clientes para nosotros.”
  </span>
)

const text7 = (
  <span>
    Además de mejorar el contacto con sus prospectos y leads, CEPEBAN deseaba y
    necesitaba tener integrada su data en su CRM. La institución usa Zoho CRM y
    para ellos era vital integrar Beex con su plataforma de gestión de
    clientes.
    <br /> <br />
    Antes, su gestión de WhatsApp se limitaba a las conversaciones, y estas, así
    como los resultados obtenidos, no podrían verse en su CRM, por lo que se les
    dificultaba el seguimiento a sus leads y así llevar una mejor gestión de sus
    potenciales acuerdos.
    <br /> <br />
    Todo esto cambio al migrar a Beex y WhatsApp Business API. Gracias a la
    estructura de APIs de nuestra plataforma, CEPEBAN ha sido capaz de integrar su canal más
    importante de conversión con Zoho CRM, por lo que tiene visualización total
    de las conversaciones con sus leads y aplicar acciones automatizadas según
    sus reglas de negocio.
    <br /> <br />
    Ahora, los agentes comerciales, supervisores y gerentes de cuentas son
    capaces de aplicar acciones automatizadas según las respuestas o acciones
    del chatbot, además de brindarle autonomía a los agentes de aplicar acciones
    sin tener que viajar entre diferentes plataformas para esto.
    <br /> <br />
    Como nos cuenta el equipo de Marketing, priorizar su atención de prospectos
    y leads es de suma importancia y con esta versión de WhatsApp y las
    integraciones conseguidas a través de nuestra plataforma, ayudan a este fin.
  </span>
)
const text8 = (
  <span>
    “Con las APIs de Beex hemos sido capaces de integrar WhatsApp con Zoho
    CRM, lo que nos ha llevado a mejorar la experiencia de uso de nuestra
    operación. La automatización que tenemos en la canalización de leads a
    nuestros agentes se ve enriquecida al brindarles datos en tiempo real y las
    automatizaciones ayudan en optimizar nuestros tiempos.”
  </span>
)

const text9 = (
  <span>
    Como es común con nuestros clientes, Beex se involucra desde el día uno
    en la implementación de nuestras soluciones. Con CEPEBAN, al conocer la
    importancia del contacto y comunicación con sus leads, brindamos asesoría
    para que sus procesos se implementen sin fricción.
    <br /> <br />
    Es por ello que nuestro equipo de Customer Success, acompañó y ayudó a su operación
    de marketing y diseñar e implemetar su chatbot para prospección por
    WhatsApp. Esto, lo lograron en solo días, tomando en cuenta el diseño
    inicial y las pruebas en un ambiente aislado para una posterior migración.
    <br /> <br />
    Como nos cuenta Oriana, el equipo siempre ha respondido a sus dudas, incluso
    programando horarios para capacitaciones específicas. También, la
    información sobre nuevas funcionalidades que podrían incorporar a su
    operación, lo que para ellos significa continuar en tendencia.
  </span>
)
const text10 = (
  <span>
    “Recomendaría 100% las soluciones de Beex por la simplificación y
    automatización que brindan. Esto, con las integraciones que hemos logrado,
    no ayudan a crear un modelo de negocio más avanzado. Además, su atención al
    cliente es un plus, tenemos seguridad en el acompañamiento que nos dan en
    todo momento.”
  </span>
)

const text11 = (
  <span>
    Estos son algunos procesos con los que Beex ayuda
    a CEPEBAN en su operación de marketing y ventas.
  </span>
)

const text14 = (
  <p style={{ marginBottom: "70px" }}>
    Beex y CEPABAN han logrado una sinergia importante debido al
    entendimiento de sus operaciones. Esto, ha llevado a implementar nuestras
    soluciones en tiempo record, de manera eficiente, logrando resultados
    importantes. Sin duda, es un caso de éxito importante para el sector
    educación, mejorando sus operaciones digitales.
  </p>
)

const data = {
  intro: {
    image: Cepeban,
    link: "https://youtu.be/TwUMacNTmDI",
    classlogo: "logo__clientes--kontigo",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoKontigo,
    text4: text4,
    title5: "Chatbots en WhatsApp para prospección",
    text5: text5,
    text6: text6,
    title7: "Integración de WhatsApp con Zoho CRM",
    text7: text7,
    text8: text8,
    fototwo: FotoKontigo2,
    title9: "Experiencia de soporte",
    text9: text9,
    text10: text10,
    text11: text11,
    text14: text14,

    metric1: metrica1,
    metric2: metrica2,
  },

  cards: [
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Canal oficial de WhatsApp Business API para su operación de marketing y ventas. Beex ha logrado mejorar el contacto y comunicación con sus prospectos y leads, mejorando su conversión digital.",
    },

    {
      title: "Chatbots",
      img: chatbots,
      info:
        "Implementación de chatbots para su operación de marketing y ventas. Diseño y creción por rangos horarios para brindar respuestas eficaces y rápidas, con disponibilidad 24x7, los 365 días del año.",
    },
    {
      title: "Integraciones vía API",
      img: integrar,
      info:
        "Implementación de procesos automáticos, gracias a estructura de API abierta para integración con su CRM Zoho. Logrando automatizar sus acciones de marketing y ventas.",
    },
  ],
}

export default data
